import './styles.scss';
import { ReactComponent as Logo } from '../../assets/LogoBlack.svg';
import Union from '../../assets/Union.svg';
import HouseImage from '../../assets/images/house.png';
import HouseFramed from '../../assets/images/houseFramed.png';
import PhoneImage from '../../assets/images/Phone.png';
import { useState } from 'react';
import { addSubscriber } from '../../utility/Services';

const ComingSoon = () => {
  const emailValidator =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [isEmailSubmitted, setEmailSubmittedStatus] = useState(false);
  const [email, setEmail] = useState('');
  const [addingSubscriber, setAddingSubscriber] = useState(false);

  const addEmailToList = (email) => {
    if (addingSubscriber) return
    setAddingSubscriber(true)
    addSubscriber(email).then(_ => {
      if (_) {
        setEmailSubmittedStatus(true)
      } else {
        setAddingSubscriber(false)
      }
    })
  }

  return (
    <div className='coming_soon_wrap'>
      {/* <header>
        <div className='content_wrap'>
          <Logo className='battalion_logo' />
        </div>
      </header> */}
      <img
        src={Union}
        className='bg_image'
        alt='bg'
        data-aos='fade-up'
        data-aos-delay='0'
        data-aos-duration='200'
      />
      <div className='main_hero_section'>
        <div className='content_wrap'>
          <div className='flex_box space_between main_wrap'>
            <div className='x2 flex_column align_start first_section'>
              <div
                className='main_heading'
                data-aos='fade-up'
                data-aos-delay='0'
                data-aos-duration='700'>
                Financing done better
              </div>
              <div
                className='sub_heading'
                data-aos='fade-up'
                data-aos-delay='0'
                data-aos-duration='800'>
                Tech powered lending that moves your real estate business
                forward.
              </div>
            </div>
            <div className='x2 '>
              <HeroImage />
            </div>
          </div>
        </div>
      </div>
      <div className='coming_soon_section'>
        <div className='content_wrap'>
          <div
            className='coming_soon_text'
            data-aos='fade-up'
            data-aos-delay='0'
            data-aos-duration='700'>
            Coming Soon
          </div>

          <div className='notify_wrap'>
            {isEmailSubmitted ? (
              <div
                key={1}
                className='notify_text thank_you_text'
                data-aos='fade-up'
                data-aos-delay='0'
                data-aos-duration='400'>
                Thank you! We will notify you of updates.
              </div>
            ) : (
              <>
                <div
                  key={2}
                  className='notify_text'
                  data-aos='fade-up'
                  data-aos-delay='0'
                  data-aos-duration='750'>
                  Get notified of any updates
                </div>
                <div
                  className='input_wrap'
                  data-aos='fade-up'
                  data-aos-delay='0'
                  data-aos-duration='800'>
                  <input
                    value={email}
                    name='email'
                    type='email'
                    required
                    placeholder='Email address'
                    title='Email address'
                    onChange={(e) => setEmail(e.currentTarget.value)}
                  />
                  <button
                    disabled={!emailValidator.test(email) || addingSubscriber}
                    onClick={() => addEmailToList(email)}>
                    Submit
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <footer>
        <div className='content_wrap'>
          <div className='flex_box'>
            <div className='x2 battalion_named'>
              <Logo className='battalion_logo' />
              <div className='text'>Tech Powered Lending</div>
            </div>
            <div className='x2 contact_us'>
              <div className='contact_title'>Contact Us</div>
              <a href='mailto:info@battalion.com'>info@battalion.com</a>
              <a href='tel:(718) 208-2000'>(718) 208-2000</a>
            </div>
          </div>
          <div className='copyright'>
            © 2022 Battalion Lending LLC. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

const HeroImage = ({ className }) => {
  return (
    <div className={`hero_image_wrap ${className}`}>
      <img
        src={PhoneImage}
        className='phone_image'
        alt=''
        data-aos='fade-up'
        data-aos-delay='0'
        data-aos-duration='900'
      />
      <div
        className='circle_wrap'
        data-aos='zoom-in'
        data-aos-delay='0'
        data-aos-duration='700'>
        <div className='circle'></div>
        {/* <img src={HouseImage} className='house_image' alt='' /> */}
      </div>
    </div>
  );
};

export default ComingSoon;
