import { useEffect, useState } from 'react';

import { ReactComponent as Logo } from '../../assets/LogoBlack.svg';

import './styles.scss';

const Header = () => {
  const [isScrolled, setScrolled] = useState(false);

  const updateScrolledBoolean = () => {
    let headerOffset = 40;
    setScrolled(window.scrollY > headerOffset);
  };

  useEffect(() => {
    updateScrolledBoolean();

    document.addEventListener('scroll', updateScrolledBoolean);

    return () => {
      document.removeEventListener('scroll', updateScrolledBoolean);
    };
  }, []);

  return (
    <header className={`${isScrolled ? 'scrolled_header' : ''}`}>
      <div className='content_wrap'>
        <div
          onClick={() => window.location.reload()}
          className='logo_wrap'
          data-aos='fade-down'
          data-aos-delay='0'
          data-aos-duration='500'>
          <Logo />
        </div>
      </div>
    </header>
  );
};

export default Header;
