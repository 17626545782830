function makeGetRequest(url) {
    return fetch(url)
    .then(res => {
      // console.log(res)
      return res.ok ? res.json() : null
    })
    .then((result) => {
      return result
    }).catch((error) => {
      alert(error);
    })
}

export function addSubscriber(email) {
    const url = `https://api.propertyintel.com/mailinglist/addsubscriber?email=${email}`
    return makeGetRequest(url).then(result => {
      if (result) {
        const { level, message } = result
        if (level === 1) {
          return true
        } else {
          alert(message)
          return false
        }
      }
    })
}