import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import ComingSoon from './page/ComingSoon';
import Header from './components/Header';



function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Header />
      <ComingSoon />
    </>
  );
}

export default App;
